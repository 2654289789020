import React from "react";

import "./Journey.scss";

import ic from "../../../_images/UniversityLogo/ic.png";
import ic2 from "../../../_images/UniversityLogo/ic-2.png";
import ic3 from "../../../_images/UniversityLogo/ic-3.png";
import ic4 from "../../../_images/UniversityLogo/ic-4.png";
import nus from "../../../_images/UniversityLogo/nus.png";
import ucl from "../../../_images/UniversityLogo/ucl.png";
import uom from "../../../_images/UniversityLogo/uom_coloured.png";
import nusIntern from "../../../_images/nus-intern.png";

const Journey = () => {
  const openPhotographInNewWindow = () => {
    window.open(nusIntern, "_blank", "noopener,noreferrer");
  };

  return (
    <table className="container-journey">
      <heading className="heading-font-journey">My Journey</heading>
      <tbody>
        <tr>
          <td className="journey-image">
            <img src={ic4} width="140px" />
          </td>
          <td className="journey-text">
            <h style={{ fontWeight: "bold", fontSize: "12px" }}>
              Pursuing a research master's degree at Imperial College London
            </h>
            <p style={{ fontSize: "12px" }}>
              I am currently pursuing a research master's degree in{" "}
              <a
                href="https://www.imperial.ac.uk/study/courses/postgraduate-taught/soft-electronic-materials/"
                className="link-text-introduction"
              >
                Soft Electronic Materials
              </a>{" "}
              at Imperial College London.
            </p>
          </td>
        </tr>
        <tr>
          <td className="journey-image">
            <img src={nus} width="140px" />
          </td>
          <td className="journey-text">
            <h style={{ fontWeight: "bold", fontSize: "12px" }}>
              Internship in the I-FIM at National University of Singapore, 2024
            </h>
            <p style={{ fontSize: "12px" }}>
              I interned at the I-FIM (Institute for Functional Intelligent
              Materials) at National University of Singapore, supervised by
              Prof. Denis Bandurin. Studied on the simulation of SNSPDs through
              analog circuits and the fabrication of magic-angle twisted bilayer
              graphene. [
              <span
                onClick={openPhotographInNewWindow}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "12px",
                }}
              >
                Journey Photograph
              </span>
              ]
            </p>
          </td>
        </tr>
        <tr>
          <td className="journey-image">
            <img src={ucl} width="140px" />
          </td>
          <td className="journey-text">
            <papertilte style={{ fontWeight: "bold", fontSize: "12px" }}>
              Graduated from University College London, 2023
            </papertilte>
            <p style={{ fontSize: "12px" }}>
              I graduated from University College London with an MSc degree
              in&nbsp;
              <a style={{ fontSize: "12px", textDecoration: "underline" }}>
                Computer Science
              </a>
              , awarded with&nbsp;
              <strong style={{ fontSize: "12px", color: "green" }}>
                Merit degree (65%)
              </strong>
              .
            </p>
          </td>
        </tr>
        <tr>
          <td className="journey-image">
            <img src={uom} width="140px" />
          </td>
          <td className="journey-text">
            <tilte style={{ fontWeight: "bold", fontSize: "12px" }}>
              Graduated from The University of Manchester, 2022
            </tilte>
            <p style={{ fontSize: "12px" }}>
              I graduated from The University of Manchester with a BSc (Hons)
              degree in{" "}
              <a style={{ fontSize: "12px", textDecoration: "underline" }}>
                Materials Science and Engineering
              </a>
              , awarded with &nbsp;
              <strong style={{ fontSize: "12px", color: "green" }}>
                First-Class degree (77%)
              </strong>
              . My GPA was 75% in Year 1, 81% in Year 2, and 75% in Year 3.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Journey;
