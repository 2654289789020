import React from "react";
import "./AboutMe.scss";

const AboutMe = () => {
  return (
    <table className="container-aboutme">
      <tbody>
        <tr>
          <td>
            <heading className="heading-font-aboutme">About Me</heading>
            <br />
            <p className="text-font-aboutme">
              Hi there 👋! My name is Zewen Kong. Currently, I am applying for a
              doctoral position. My research interests are in the emerging
              devices (e.g., memristors and wearable electronics) integration
              and nanofabrication, as well as algorithms and architectures based
              on emerging devices.
            </p>
            {/* <p className="text-font-aboutme">
              Download a PDF copy of my resume&nbsp;
              <a
                href="/files/pdf/Resume.pdf"
                download="Resume.pdf"
                style={{ textDecoration: "none", fontSize: "12px" }}
              >
                here
              </a>
              , or&nbsp;
              <a
                href="/files/pdf/Resume.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", fontSize: "12px" }}
              >
                review
              </a>
              &nbsp;it in a new Tab.
            </p> */}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AboutMe;
